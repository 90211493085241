import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';

import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthService {
    private _authenticated: boolean = false;
    private apiUrl = environment.API_URL;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _toastrService: ToastrService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        if (token != undefined) localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    get email(): string {
        return this._userService.email;
    }

    get nombre(): string {
        return this._userService.nombre;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: {
        correo: string;
        contrasenia: string;
    }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError(() => new Error('El usuario ya inicio sesión'));
        }

        return this._httpClient
            .post(`${this.apiUrl}/iniciar-sesion`, credentials)
            .pipe(
                switchMap((response: any) => {
                    // // Store the access token in the local storage
                    this.accessToken = response.data.token;

                    // // Set the authenticated flag to true
                    this._authenticated = response.status == 'success';

                    // // Store the user on the user service
                    this._userService.email = credentials.correo;

                    this._userService.nombre = response.data.nombre_usuario;

                    this._userService.idUser = response.data.id_usuario;

                    // Return a new observable with the response
                    return of(response);
                })
            );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken({ isManuallyHandled = false }): Observable<any> {
        // Renew token
        if (isManuallyHandled)
            return this._httpClient
                .post(
                    `${this.apiUrl}/actualizar-token`,
                    {
                        correo: this._userService.email,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${this.accessToken}`,
                        },
                    }
                )
                .pipe(
                    catchError((err: HttpErrorResponse) => {
                        console.error(err);
                        // Return false
                        return of(false);
                    }),
                    switchMap((response: any) => {
                        // Store the access token in the local storage
                        this.accessToken = response.data.token;

                        // Return true
                        return of(true);
                    })
                );

        return of(true);
    }

    /**
     * Sign out
     */
    signOut({
        withErrorMessage = '',
        withWarningMessage = '',
        withMessage = '',
    }): Observable<any> {
        if (withErrorMessage.length)
            this._toastrService.error(withErrorMessage, 'Error');

        if (withWarningMessage.length)
            this._toastrService.warning(withWarningMessage, 'Advertencia');

        if (withMessage.length) this._toastrService.info(withMessage);
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        this._userService.removeUser();

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: {
        name: string;
        email: string;
        password: string;
        company: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            this.signOut({
                withWarningMessage:
                    'Su sesión ha expirado, inicie sesión de nuevo',
            });
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken({});
    }
}
